import rcod1 from "../files/images/portfolio/rcod-1.png"
import rcod2 from "../files/images/portfolio/rcod-2.png"
import rcod3 from "../files/images/portfolio/rcod-3.png"
import rcod4 from "../files/images/portfolio/rcod-4.png"
import rcod5 from "../files/images/portfolio/rcod-5.png"
import rcod6 from "../files/images/portfolio/rcod-6.png"
import gePdx1 from "../files/images/portfolio/ge-pdx-1.png"
import gePdx2 from "../files/images/portfolio/ge-pdx-2.png"
import gePdx3 from "../files/images/portfolio/ge-pdx-3.png"
import gePdx4 from "../files/images/portfolio/ge-pdx-4.png"
import gePdx5 from "../files/images/portfolio/ge-pdx-5.png"
import gePdx6 from "../files/images/portfolio/ge-pdx-6.png"
import gePdx7 from "../files/images/portfolio/ge-pdx-4.png"
import gePdx8 from "../files/images/portfolio/ge-pdx-5.png"
import gePdx9 from "../files/images/portfolio/ge-pdx-6.png"
import gePdx10 from "../files/images/portfolio/ge-pdx-6.png"
// import debtsRta1 from "../files/images/portfolio/debts-rta-1.png"
// import debtsRta2 from "../files/images/portfolio/debts-rta-2.png"
// import vaccinesRta1 from "../files/images/portfolio/vaccines-rta-1.png"
import vaccinesRta2 from "../files/images/portfolio/vaccines-rta-2.png"
import vaccinesRta3 from "../files/images/portfolio/vaccines-rta-3.png"
import vaccinesRta4 from "../files/images/portfolio/vaccines-rta-4.png"
import vaccinesRta5 from "../files/images/portfolio/vaccines-rta-5.png"
import rcodUserManual from "../files/pdfs/rcod-user-manual.pdf"
import gePdxUserManual from "../files/pdfs/ge-pdx-user-manual.pdf"

const portfolio = {
  pageName: {
    en: `Portfolio`,
    th: `ผลงาน`,
  },
  title: {
    en: `Portfolio`,
    th: `ผลงาน`,
  },
  seeMoreButton: {
    en: `See more`,
    th: `ดูเพิ่มเติม`,
  },
  data: [
    {
      name: {
        en: `Government Employee Personal Data Exchange (GE-PDX)`,
        th: `โปรแกรมพนักงานราชการในระบบฐานข้อมูลกำลังพลอิเล็กทรอนิกส์`,
      },
      techUses: `(Gastby.js + Strapi)`,
      galleryPhotos: [
        {
          original: gePdx1,
          alt: `ge-pdx-1`,
          fullname: `portfolio/ge-pdx-1.png`,
        },
        {
          original: gePdx2,
          alt: `ge-pdx-2`,
          fullname: `portfolio/ge-pdx-2.png`,
        },
        {
          original: gePdx3,
          alt: `ge-pdx-3`,
          fullname: `portfolio/ge-pdx-3.png`,
        },
        {
          original: gePdx4,
          alt: `ge-pdx-4`,
          fullname: `portfolio/ge-pdx-4.png`,
        },
        {
          original: gePdx5,
          alt: `ge-pdx-5`,
          fullname: `portfolio/ge-pdx-5.png`,
        },
        {
          original: gePdx6,
          alt: `ge-pdx-6`,
          fullname: `portfolio/ge-pdx-6.png`,
        },
        {
          original: gePdx7,
          alt: `ge-pdx-7`,
          fullname: `portfolio/ge-pdx-7.png`,
        },
        {
          original: gePdx8,
          alt: `ge-pdx-8`,
          fullname: `portfolio/ge-pdx-8.png`,
        },
        {
          original: gePdx9,
          alt: `ge-pdx-9`,
          fullname: `portfolio/ge-pdx-9.png`,
        },
        {
          original: gePdx10,
          alt: `ge-pdx-10`,
          fullname: `portfolio/ge-pdx-10.png`,
        },
      ],
      pdf: {
        title: {
          en: `See a document`,
          th: `ดูเอกสารเพิ่มเติม`,
        },
        name: `ge-pdx`,
        source: gePdxUserManual,
      },
      externalLink: {
        link: `https://github.com/darkholy40/dop-ge-pdx-gastby`,
        title: {
          en: `GitHub`,
          th: `GitHub`,
        },
        icon: `github`,
      },
    },
    {
      name: {
        en: `RCOD - Reduction of Copies of Documents`,
        th: `ระบบทดแทนสำเนาเอกสารราชการ`,
      },
      techUses: `(Gastby.js + API (Node.js) + 3rd party API)`,
      galleryPhotos: [
        {
          original: rcod1,
          alt: `rcod-1`,
          fullname: `portfolio/rcod-1.png`,
        },
        {
          original: rcod2,
          alt: `rcod-2`,
          fullname: `portfolio/rcod-2.png`,
        },
        {
          original: rcod3,
          alt: `rcod-3`,
          fullname: `portfolio/rcod-3.png`,
        },
        {
          original: rcod4,
          alt: `rcod-4`,
          fullname: `portfolio/rcod-4.png`,
        },
        {
          original: rcod5,
          alt: `rcod-5`,
          fullname: `portfolio/rcod-5.png`,
        },
        {
          original: rcod6,
          alt: `rcod-6`,
          fullname: `portfolio/rcod-6.png`,
        },
      ],
      pdf: {
        title: {
          en: `See a document`,
          th: `ดูเอกสารเพิ่มเติม`,
        },
        name: `rcod`,
        source: rcodUserManual,
      },
      // externalLink: {
      //   link: `https://github.com/darkholy40/dop-copies-of-document-reduction`,
      //   title: {
      //     en: `GitHub`,
      //     th: `GitHub`,
      //   },
      //   icon: `github`,
      // },
    },
    // {
    //   name: {
    //     en: `Financial status information importing system`,
    //     th: `ระบบนำเข้าข้อมูลสถานภาพทางการเงิน`,
    //   },
    //   techUses: `(Gatsby.js + API)`,
    //   galleryPhotos: [
    //     {
    //       original: debtsRta1,
    //       alt: `debts-rta-1`,
    //       fullname: `portfolio/debts-rta-1.png`,
    //     },
    //     {
    //       original: debtsRta2,
    //       alt: `debts-rta-2`,
    //       fullname: `portfolio/debts-rta-2.png`,
    //     },
    //   ],
    // },
    {
      name: {
        en: `Covid-19 Vaccinated Army List`,
        th: `ระบบดูประวัติการฉีดวัคซีนโควิด - 19 ของกำลังพล ทบ.`,
      },
      techUses: `(Gastby.js + API (Node.js) + 3rd party API)`,
      galleryPhotos: [
        // {
        //   original: vaccinesRta1,
        //   alt: `vaccines-rta-1`,
        //   fullname: `portfolio/vaccines-rta-1.png`,
        // },
        {
          original: vaccinesRta2,
          alt: `vaccines-rta-2`,
          fullname: `portfolio/vaccines-rta-2.png`,
        },
        {
          original: vaccinesRta3,
          alt: `vaccines-rta-3`,
          fullname: `portfolio/vaccines-rta-3.png`,
        },
        {
          original: vaccinesRta4,
          alt: `vaccines-rta-4`,
          fullname: `portfolio/vaccines-rta-4.png`,
        },
        {
          original: vaccinesRta5,
          alt: `vaccines-rta-5`,
          fullname: `portfolio/vaccines-rta-5.png`,
        },
      ],
    },
  ],
  docs: {
    downloadButton: {
      en: `Download`,
      th: `ดาวน์โหลด`,
    },
    isDownloadedText: {
      en: `Downloaded`,
      th: `ดาวน์โหลดแล้ว`,
    },
  },
}

export default portfolio
