import React from "react"
import { useSelector } from "react-redux"
import PropTypes from "prop-types"
import styled from "styled-components"

import getSiteColor from "../functions/get-site-color"
import hexToRgbaColor from "../functions/hex-to-rgba-color"

const Block = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid
    ${({ theme }) =>
      theme === `light` ? `rgba(0, 0, 0, 0.12)` : `rgba(255, 255, 255, 0.12)`};
  padding: 8px 16px;
  border-radius: 24px;
  min-height: 24px;
  min-width: 96px;
  user-select: none;
  cursor: pointer;
  background-color: ${({ theme, siteColor }) => getSiteColor(siteColor, theme)};
  color: ${({ theme }) => (theme === `light` ? `#fff` : `#000`)};
  transition: border 0.3s, background-color 0.3s, color 0.3s, transform 0.1s;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);

  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme, siteColor }) =>
        hexToRgbaColor(getSiteColor(siteColor, theme), 0.9)};
      transition: border 0.1s, background-color 0.1s, color 0.1s;
    }
  }

  &:active {
    transform: scale(0.95);
  }

  svg {
    font-size: 1.5rem;
  }
`

const ApirakButton = props => {
  const { theme, siteColor } = useSelector(({ mainReducer }) => mainReducer)

  return (
    <Block {...props} theme={theme} siteColor={siteColor} role="presentation">
      {props.children}
    </Block>
  )
}

ApirakButton.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ApirakButton
