import React, { forwardRef, useEffect, useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { useSelector, useDispatch } from "react-redux"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Zoom,
} from "@mui/material"
import styled from "styled-components"
import { GiFullFolder } from "react-icons/gi"
import { FaExternalLinkAlt } from "react-icons/fa"
import {
  BsFillFileEarmarkPdfFill,
  BsGithub,
  BsDownload,
  BsCheckLg,
} from "react-icons/bs"
import { MdClose } from "react-icons/md"
import { useResizeDetector } from "react-resize-detector"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Section from "../components/section"
import Gallery from "../components/gallery"
import ApirakButton from "../components/apirak-button"
import getSiteColor from "../functions/get-site-color"
import portfolio from "../static/portfolio"
import { setCurrentPage } from "../states/slices/main-slice"
import { playSound } from "../states/slices/sound-slice"

const StyledDialogContent = styled(DialogContent)`
  position: relative;

  @media (hover: hover) {
    &:hover {
      .dim {
        opacity: 1;
        transition: opacity 0.1s, background-color 0.1s;
      }
    }
  }
`

const ShadowCover = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 1s, background-color 1s;

  @media (hover: hover) {
    opacity: 0;
  }
`

const Transition = forwardRef((props, ref) => {
  return <Zoom easing="ease" ref={ref} {...props} />
})

const Portfolio = () => {
  const { lang, theme, siteColor } = useSelector(
    ({ mainReducer }) => mainReducer
  )
  const dispatch = useDispatch()

  const [pdfDialog, setPdfDialog] = useState({
    open: false,
    source: null,
    title: ``,
    name: ``,
  })
  const [docIsDownloaded, setDocIsDownload] = useState(false)
  const canvasResizeDetector = useResizeDetector()

  useEffect(() => {
    dispatch(
      setCurrentPage({
        page: `portfolio`,
        group: `portfolio`,
      })
    )
  }, [dispatch])

  const closePdfDialog = () => {
    dispatch(playSound(`cancel`))
    setPdfDialog(prev => ({ ...prev, open: false }))

    setTimeout(() => {
      setDocIsDownload(false)
    }, 200)
  }

  return (
    <Layout>
      <Seo title={portfolio.pageName[lang]} />
      {portfolio.data.map((ach, achIndex) => {
        let props = {}

        if (achIndex === 0) {
          props = {
            title: portfolio.title[lang],
            icon: <GiFullFolder />,
          }
        }

        return (
          <Section key={`ach_${achIndex}`} {...props}>
            <div style={{ marginBottom: `1rem` }}>
              <p>{ach.name[lang]}</p>
              <p
                style={{
                  fontSize: `0.75rem`,
                  color: `rgba(${
                    theme === `light` ? `0, 0, 0` : `255, 255, 255`
                  }, 0.75)`,
                  transition: `color 0.3s`,
                }}
              >
                {ach.techUses}
              </p>
              <Gallery photos={ach.galleryPhotos} grid={ach.grid} />
              {ach.pdf !== undefined && (
                <div
                  style={{
                    display: `flex`,
                    justifyContent: `center`,
                    marginTop: `1rem`,
                  }}
                >
                  <ApirakButton
                    onClick={() => {
                      setPdfDialog({
                        open: true,
                        source: ach.pdf.source,
                        title: ach.name[lang],
                        name: ach.pdf.name,
                      })
                      dispatch(playSound(`confirm`))
                    }}
                  >
                    <BsFillFileEarmarkPdfFill style={{ marginRight: 8 }} />
                    <span>{ach.pdf.title[lang]}</span>
                  </ApirakButton>
                </div>
              )}
              {ach.externalLink !== undefined && (
                <div
                  style={{
                    display: `flex`,
                    justifyContent: `center`,
                    marginTop: `1rem`,
                  }}
                >
                  <Button
                    href={ach.externalLink.link}
                    target="_blank"
                    rel="noopener"
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                      dispatch(playSound(`confirm`))
                    }}
                  >
                    {ach.externalLink.icon !== undefined &&
                      (() => {
                        switch (ach.externalLink.icon) {
                          case `github`:
                            return (
                              <BsGithub
                                style={{ fontSize: `1.25rem`, marginRight: 8 }}
                              />
                            )

                          default:
                            break
                        }
                      })()}
                    {ach.externalLink.title[lang]}
                    {ach.externalLink.icon === undefined && (
                      <FaExternalLinkAlt style={{ marginLeft: 8 }} />
                    )}
                  </Button>
                </div>
              )}
            </div>

            <Dialog
              sx={{
                ".MuiBackdrop-root": { backgroundColor: `rgba(0, 0, 0, 0.35)` },
              }}
              // fullScreen
              fullWidth
              maxWidth="md"
              open={pdfDialog.open}
              onClose={closePdfDialog}
              TransitionComponent={Transition}
            >
              <DialogTitle
                sx={{
                  backgroundColor: getSiteColor(siteColor, theme),
                  color:
                    theme !== `light` ? `rgb(0, 0, 0)` : `rgb(255, 255, 255)`,
                  paddingRight: `6.5rem`,
                  whiteSpace: `nowrap`,
                  overflow: `hidden`,
                  textOverflow: `ellipsis`,
                }}
              >
                {pdfDialog.title}
              </DialogTitle>
              <StyledDialogContent
                style={{ padding: `0.5rem` }}
                sx={{ height: `calc(100vh - 148px)`, overflow: `auto` }}
                ref={canvasResizeDetector.ref}
              >
                <div
                  style={{
                    display: `flex`,
                    justifyContent: `center`,
                    position: `relative`,
                  }}
                >
                  <div
                    style={{
                      width: `100%`,
                      height: `calc(100vh - 148px)`,
                      display: `flex`,
                      justifyContent: `center`,
                      alignItems: `center`,
                    }}
                  >
                    <div style={{ width: `100%`, height: `100%` }}>
                      {(() => {
                        switch (pdfDialog.name) {
                          case `ge-pdx`:
                            return (
                              <StaticImage
                                src="../files/images/portfolio/pdf/ge-pdx/ge-pdx-01.png"
                                alt="ge-pdx-cover"
                                style={{
                                  width: `100%`,
                                  height: `100%`,
                                }}
                                imgStyle={{
                                  objectFit: `contain`,
                                  objectPosition: `center top`,
                                }}
                                placeholder="blurred"
                                formats={[`auto`, `webp`, `avif`]}
                                draggable={false}
                              />
                            )

                          default:
                            return (
                              <StaticImage
                                src="../files/images/portfolio/pdf/rcod/rcod-01.png"
                                alt="rcod-cover"
                                style={{
                                  width: `100%`,
                                  height: `100%`,
                                }}
                                imgStyle={{
                                  objectFit: `contain`,
                                  objectPosition: `center top`,
                                }}
                                placeholder="blurred"
                                formats={[`auto`, `webp`, `avif`]}
                                draggable={false}
                              />
                            )
                        }
                      })()}
                    </div>
                  </div>
                </div>
                <ShadowCover className="dim">
                  <div>
                    {docIsDownloaded ? (
                      <span
                        style={{
                          display: `flex`,
                          justifyContent: `center`,
                          alignItems: `center`,
                          color: `#FFF`,
                        }}
                      >
                        <BsCheckLg style={{ marginRight: 8 }} />
                        {portfolio.docs.isDownloadedText[lang]}
                      </span>
                    ) : (
                      <ApirakButton
                        onClick={() => {
                          dispatch(playSound(`confirm`))
                          setDocIsDownload(true)

                          // using Java Script method to get PDF file
                          fetch(pdfDialog.source).then(response => {
                            response.blob().then(blob => {
                              // Creating new object of PDF file
                              const fileURL = window.URL.createObjectURL(blob)

                              // Setting various property values
                              let alink = document.createElement("a")
                              alink.href = fileURL
                              alink.download = `${pdfDialog.title}.pdf`
                              alink.click()
                            })
                          })
                        }}
                      >
                        <BsDownload style={{ marginRight: 8 }} />
                        <span>{portfolio.docs.downloadButton[lang]}</span>
                      </ApirakButton>
                    )}
                  </div>
                </ShadowCover>
              </StyledDialogContent>
              <DialogActions sx={{ position: `absolute`, top: 4, right: 0 }}>
                <IconButton onClick={closePdfDialog}>
                  <MdClose
                    style={{
                      color:
                        theme !== `light`
                          ? `rgb(0, 0, 0)`
                          : `rgb(255, 255, 255)`,
                    }}
                  />
                </IconButton>
              </DialogActions>
            </Dialog>
          </Section>
        )
      })}
    </Layout>
  )
}

export default Portfolio
